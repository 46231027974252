<template>
	<v-container>
		<p>
			{{ $t("attribution.sponsorblock-text") }} <a href="https://sponsor.ajay.app">https://sponsor.ajay.app</a>
		</p>
	</v-container>
</template>

<script>
export default {
	name: 'Attribution',
};
</script>
